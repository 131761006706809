  .PolicyEngineDetailsTable {
    max-height: 400px;
    height: auto;
    overflow: auto;
  }

  .PolicyEngineFootPrintContainerTable {
    width: inherit;
    max-height: 400px;
    height: auto;
    overflow: auto;
  }