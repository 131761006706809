  .SASDetailsTable {
    max-height: 400px;
    height: auto;
    overflow: auto;
  }

  .SASFootPrintContainerTable {
    width: inherit;
    max-height: 400px;
    height: auto;
    overflow: auto;
  }