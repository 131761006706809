@use '~@amzn/awsui-design-tokens/polaris' as awsui;

.multiselect {
  width: 100%;
  max-width: 250px;
  padding-right: 10px;
}

.multiselectContainer {
  display: flex;
  flex-direction: row;
  overflow: hidden;
}

