  .CostMetricsAggregatedDetailsTable {
    max-height: 400px;
    height: auto;
    overflow: auto;
  }

  .CostMetricsDetailsFootPrintContainerTable {
    width: inherit;
    max-height: 400px;
    height: auto;
    overflow: auto;
    padding-top: 10px;
    margin-top: 10px;
  }