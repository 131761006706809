// @use '@amzn/awsui-design-tokens/polaris.scss' as awsui;
@use '~@amzn/awsui-design-tokens/polaris' as awsui;
@import '@amzn/awsui-global-styles/polaris.css';
@import './styles/welcome-widget-styles.scss';
@import 'src/components/generic-components/broadcast-video/styles/BroadcastPlayer.scss';

html {
  overflow: hidden;
  height: 100%;
}

body {
  margin: 0px;
  overflow: auto;
  height: 100%;

  /* Chrome */
  ::-webkit-scrollbar {
    width: 8px;
  }

  ::-webkit-scrollbar-thumb {
    background-color: #888;
    border-radius: 5px;
  }

  ::-webkit-scrollbar-thumb:hover {
    background-color: #a9a9a9;
  }

  /* For Firefox */
  /* Note: Firefox currently does not support customizing the scrollbar color directly,
   so we will use a workaround using pseudo-elements */
  * {
    scrollbar-color: #c2c2c2 transparent;
    scrollbar-width: thin;
  }

  *::-moz-scrollbar-thumb {
    background-color: #c2c2c2;
    border-radius: 5px;
  }

  *::-moz-scrollbar-thumb:hover {
    background-color: #a9a9a9;
  }
}

.app-layout-body {
  margin-bottom: 80px;
}

.margin-top-20 {
  margin-top: 20px;
}

.display-flex-row {
  display: flex !important;
  flex-direction: row !important;
}

#h {
  display: block;
  position: sticky;
  height: 95px;

  z-index: 1002;

  top: 0;
  left: 0;
  right: 0;

  margin: 0;
  font-family: 'Amazon Ember', 'Helvetica Neue', Roboto, Arial, sans-serif;

  [class^='awsui_logo'] {
    width: 32px !important;
    height: 32px !important;
  }

  [class^='awsui_icon'] {
    color: currentColor !important;
  }

  [class^='awsui_search'] {
    max-width: 360px !important;
    margin-left: 200px !important;
  }
}

.app-primary-top-navigation {
  [class^='awsui_padding-box'] {
    padding-left: 20px !important;
  }

  [class^='awsui_item-element_'][class*='awsui_variant-navigation_'][class*='awsui_highlighted_']:not(#\9) {
    color: inherit; // or any other color value

    &:hover {
      color: var(--color-text-accent-exu66k, #0073bb); // or any other color value you want on hover
    }
  }
}

.app-secondary-top-navigation {
  padding-left: 20px !important;

  height: 45px !important;
  min-height: 45px !important;

  background-color: #161d26 !important; // TODO: Replace with appropriate awsui variable when available  

  border-radius: 0 !important;
  box-shadow: none !important;
  display: flex;
  justify-content: center;
  align-items: center;

  .app-secondary-nav-all-apps-button {
    cursor: pointer;

    text-align: center;
    display: flex;
    justify-content: center;
    align-items: center;

    .display-name-prefix-icon {
      color: awsui.$color-text-home-header-default !important;
      width: 16px;
      height: 16px;
    }

    .display-name {
      color: awsui.$color-text-home-header-default !important;
      vertical-align: middle !important;
    }

    .display-name-suffix-icon {
      color: awsui.$color-text-home-header-default !important;
      vertical-align: middle !important;
    }
  }

  .global-secondary-nav-item {
    border-left: 1px solid #59606a;

    .display-name {
      color: awsui.$color-text-home-header-default !important;
      cursor: pointer;
    }
  }
}

.footer {
  display: block;
  position: fixed;
  height: 30px;

  z-index: 1002;

  bottom: 0;
  left: 0;
  width: 100%;
  margin: 0px;
  padding: 0px;

  color: #ffffff !important;
  background: awsui.$color-background-home-header !important;

  .footer-content {
    @extend .display-flex-row;
    flex-wrap: wrap !important;
    gap: 20px;

    padding-top: 4.5px;
    padding-bottom: 4.5px;
    padding-left: 20px;

    color: #ffffff !important;
  }
}

.star-rating {
  display: flex;
  gap: 10px;
  justify-content: center;
  padding-left: 10px;
  padding-right: 10px;

  .star-wrapper-small {
    font-size: 30px !important;
    color: awsui.$color-charts-orange-300 !important;
  }

  .star-wrapper-medium {
    font-size: 40px !important;
    color: awsui.$color-charts-orange-300 !important;
  }
}

.root {
  list-style-type: none;
  margin: 0;
  padding: 0;

  li {
    border-top: 1px solid awsui.$color-border-divider-default;
    padding: 0.8rem 0;
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;

    &:first-child {
      padding-top: 0;
      border-top: none;
    }

    &:last-child {
      padding-bottom: 0;
    }
  }
}

.horizontal-bar {
  position: relative;
  height: 1px;
  background-color: #e9ebed;

  &::before {
    content: '';
    position: absolute;
    top: 30%;
    left: 0;
    right: 0;
    height: 1px;
    background-color: #e9ebed;
  }

  margin-bottom: 2px !important;
}

.thin-border {
  border: 1px solid awsui.$color-border-control-default;
  border-radius: 6px;
  padding: 4px 10px 4px 10px;
}

.no-border {
  border: 1px solid awsui.$color-border-container-top;
  border-radius: 6px;
  padding: 3px;
}

.announcement-widget-row {
  min-height: 5.6rem;

  .monthDate {
    height: 5rem !important;
    width: 5rem !important;
    // border: solid 1px red;
  }

  .appNameInAnnouncementWidget {
    width: 20rem !important;

    .app-name-in-badge {
      cursor: pointer !important;
    }
  }
}

.limited-width-header {
  [class^='awsui_description'] {
    max-width: 800px !important;
  }
}

.app-name-with-link {
  cursor: pointer !important;

  &:hover {
    color: awsui.$color-border-button-normal-hover !important;
  }
}

.custom-breadcrumb-button {
  padding-left: 0px !important;
  font-size: small !important;
  font-weight: normal !important;
}

.admin-console-container-item {
  width: 504px;
  height: 180px;
}

.admin-console-feedback-container {
  margin-top: 10px;
  width: 365px;
}

.admin-console-app-wizard {
  [class^='awsui_tools-container'] {
    [class^='awsui_hide-tools'] {
      [class^='awsui_icon'] {
        display: none !important;
      }
    }
  }
}

.inline-table-horizontal-form {
  @extend .display-flex-row;
  flex-wrap: wrap !important;
  gap: 20px;

  .welcome-links-display-name-field {
    width: 30%;
  }

  .welcome-links-link-field {
    width: 40%;
  }

  .welcome-links-description {
    height: 200px !important;
  }

  .flex-grow-field {
    flex: 1 !important;
  }

  .add-new-button {
    margin-top: 23px !important;
    height: fit-content !important;
  }
}

.width-10rem {
  width: 10rem;
}

.width-15rem {
  width: 15rem;
}

.width-45rem {
  width: 45rem;
}

.width-130px {
  width: 130px;
}

.width-20rem {
  width: 20rem;
}

.width-25rem {
  width: 25rem;
}

.width-50rem {
  width: 50rem;
}

.inline {
  display: inline-block;
}

.flex-grow {
  flex-grow: 1;
}

.all-apps-header-action-bar {
  @extend .display-flex-row;
  width: 100%;
  flex-wrap: wrap;
  gap: 10px;
  justify-content: space-between;
  margin-top: 10px;

  .all-apps-header-action-property {
    flex: 65% !important;
  }

  .all-apps-header-action-item {
    margin-left: 5px;
    margin-right: 5px;
  }
}

.margin-left-10px {
  margin-left: 10px;
}

.margin-left-20px {
  margin-left: 20px;
}

.margin-top-10px {
  margin-top: 10px;
}

.all-apps-category-container {
  width: 305px;
  height: 140px;

  .all-apps-category-container-header {
    padding-bottom: 4px !important;
  }
}

// code-editor in Announcement component set to release in P1
.code-editor {
  @extend .display-flex-row;
}

.code-editor > div:nth-child(1) {
  width: 50% !important;
  margin: 10px !important;
  flex-grow: 1;
}

.code-editor > div:nth-child(2) {
  margin: 10px !important;
  flex-grow: 1;
}
