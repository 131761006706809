@use '~@amzn/awsui-design-tokens/polaris' as awsui;

.globalDropdown {
  background-color: transparent !important;

  [class^='awsui_dialog'] {
    position: absolute !important;
    top: 102px !important;
    right: auto !important;
    left: 1px !important;

    [class^='awsui_container'] {
      background: #161d26 !important; // TODO: Replace with appropriate awsui variable when available  
      height: calc(100vh - 200px);
      width: 800px;

      [class^='awsui_header'] {
        display: none;
      }

      [class^='awsui_content'] {
        padding: 15px 0px 0px 20px !important;
        // border: solid red 2px !important;
        height: calc(100vh - 220px) !important;
        overflow: auto;

        [class^='modal-content'] {
          display: flex;
          flex-direction: row;
          gap: 5px;

          [class^='horizontal-bar-above-admin'] {
            // width: 100%;
            border: 1px solid #414750;
            margin: 20px 10px 15px 0px;
          }

          [class^='radio-selection-type'] {
            display: flex !important;
            flex-direction: row;
            align-items: center;
            justify-content: space-between;

            [class^='awsui_label'] {
              color: awsui.$color-text-home-header-default !important;
            }

            [class^='awsui_wrapper'] {
              margin-top: 0px !important;
              padding-left: 20px !important;

              [class^='awsui_content'] {
                padding-left: 0px !important;
              }
            }
          }


          [class^='grid-content-item'] {
            color: #ffffff !important;
            cursor: pointer !important;
          }

          [class^='grid-content-description'] {
            color: #8D99A8 !important;
            cursor: pointer !important;
          }

          [class^='view-all-apps'] {
            &:hover [class^='grid-content-item'] {
              color: awsui.$color-background-button-primary-default !important;
            }
          }


          [class^='grouped-category'] {
            color: #D1D5DB !important;
            cursor: auto !important;

            [class^='grouped-category-app'] {

              [class^='grouped-category-app-icon-holder'] {
                width: 20px;
                cursor: pointer !important;
              }

              [class^='grouped-category-app-name'] {
                color: #ffffff !important;
                cursor: pointer !important;
              }

              [class^='grouped-category-app-description'] {
                color: #8D99A8 !important;
                cursor: pointer !important;
              }

              &:hover [class^='grouped-category-app-name'] {
                color: awsui.$color-background-button-primary-default !important;
              }
            }

          }
        }
      }
    }

    [class^='grid-content-description'] {
      color: awsui.$color-text-counter !important;
    }

    [class^='col-1'] {
      width: 25% !important;
      height: calc(100vh - 230px) !important;
      // border: solid yellow 2px;
      border-right: 1px solid #414750;

      [class^='global-side-nav-items'] {
        color: #ffffff !important;
        cursor: pointer !important;
      }

      [class^='global-side-nav-items-selected'] {
        color: awsui.$color-background-button-primary-default !important;
        cursor: pointer !important;
      }
    }

    [class^='col-2'] {
      width: 90% !important;
      // border: solid red 2px;
      /* Add the following styles for vertical scrolling */
      height: calc(100vh - 230px) !important;
      overflow-y: auto;

      padding-left: 10px;

      [class^='awsui_content'] {
        color: #ffffff !important;
        padding: 0px 0px 0px 10px !important;
        height: auto !important;
        border: none !important;
      }
    }

    [class^='col-3'] {
      width: 5% !important;
      height: calc(100vh - 230px) !important;
      // border: solid blue 2px;
      padding-right: 5px;
    }
  }
}