.video-thumbnail-button {
  border: none;
  background: none;
  padding: 0;
  cursor: pointer;
  width: 100%;
}

.broadcast-video-container {
  position: relative;
  width: 100%;
  padding-bottom: 56.25%; // 16:9 aspect ratio
  height: 0;
  overflow: hidden;

  iframe {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border: 0;
  }
}

.broadcast-video-player {
  width: 100%;
  height: 100%;
  border: none;
  background-color: #000000;
  flex: 1;

  &:focus {
    outline: none;
    border: 2px solid #539fe5;
  }
}

.broadcast-video-preview-container {
  position: relative;
  width: 100%;
  cursor: pointer;
}

.broadcast-video-preview {
  width: 100%;
  border: none;
  background-color: #000000;
}

.broadcast-video-play-button {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 60px;
  height: 60px;
  background-color: rgba(255, 255, 255, 0.9);
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: background-color 0.2s ease-in-out;
  border: none;
  padding: 0;
  cursor: pointer;

  &:hover {
    background-color: rgba(255, 255, 255, 1);
  }

  &:focus {
    outline: 2px solid #539fe5;
    outline-offset: 2px;
  }
}

.broadcast-video-loading {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: rgba(0, 0, 0, 0.7);
}

.broadcast-video-modal-content {
  height: 100%;
  display: flex;
  flex-direction: column;

  &-container {
    border: none;
  }
}

// The height of 350px is chosen to:
// 1. Match the standard Board item height (approximately 5 row spans)
// 2. Provide optimal viewing experience for 16:9 video content
// 3. Ensure consistent height across different screen sizes
.team-video-player {
  height: 350px; // Fixed height to maintain consistency with Board item dimensions
}
