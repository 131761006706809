@use '~@amzn/awsui-design-tokens/polaris' as awsui;

.riskDetailsTable {
  max-height: 600px;
  height: auto;
  overflow: auto;
}
.risMmetricsContainer {
  max-height: 700px;
  height: auto;
  width: auto;
  overflow: auto;
}
.riskMetricsTable {
  max-height: 200px;
  height: auto;
  overflow: auto;
}